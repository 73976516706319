<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
  >
    <template #content>
      <h3 class="mb-8">Terms of Service</h3>
      <h5 class="text-lg mb-6">1. School Board Portal</h5>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">About us</p>
        <p class="text-truegray-500">
          Schooled Limited (<a
            href="https://www.schooled.nz/"
            rel="noopener noreferrer"
            target="_blank"
            >https://www.schooled.nz/</a
          >
          ) operates the School Portal application. Our technology partner, Hail
          (<a
            href="https://get.hail.to/"
            rel="noopener noreferrer"
            target="_blank"
            >https://get.hail.to/</a
          >), developed and delivers the Portal application. Throughout these
          Terms of Service, "We," "Us," and "Our" refer collectively to Schooled
          Limited and Hail Limited.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Purpose</p>
        <p class="text-truegray-500">
          The School Portal provides a secure location to store board
          information, administer board meetings, members and collate board
          documents.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Applicability</p>
        <p class="text-truegray-500">
          These terms apply to all users, whether accessing the application as
          registered user (administrator or board member access). Use includes
          browsing, registering, or utilising any features of the application.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Acceptance of Terms</p>
        <p class="text-truegray-500">
          By using the Board Portal application, you confirm your acceptance of
          these terms and agree to comply with them.
        </p>
      </div>

      <h5 class="text-lg mb-6">2. Your account</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Account Security</p>
        <p class="text-truegray-500">
          Registered users are responsible for maintaining the confidentiality
          of their account credentials. You must notify us immediately of any
          unauthorised use of your account.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Prohibited Actions</p>
        <p class="text-truegray-500">
          You must not share your login details or allow any unauthorised person
          to access the application using your credentials
        </p>
      </div>

      <h5 class="text-lg mb-6">3. Emails</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Communication Consent</p>
        <p class="text-truegray-500">
          By using the application, you agree to receive emails related to your
          registration and transactions through the application.
        </p>
      </div>

      <h5 class="text-lg mb-6">4. Intellectual Property</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Ownership</p>
        <p class="text-truegray-500">
          We retain all intellectual property rights in the application, except
          for user-uploaded content, such as board reports/information. You may
          not use or distribute our intellectual property without prior written
          consent.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">User Content</p>
        <p class="text-truegray-500">
          By uploading content, you grant us a non-exclusive, royalty-free
          license to use it for purposes related to the delivery of the board
          portal service.
        </p>
      </div>

      <h5 class="text-lg mb-6">5. Disclaimer</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">No Warranties</p>
        <p class="text-truegray-500">
          We strive to provide a reliable and effective service, but we cannot
          make guarantees about the application. Specifically:
        </p>
        <ul class="text-truegray-500">
          <li>
            We do not promise that all information in the application will be
            accurate or free of mistakes.
          </li>
          <li>
            The application may experience interruptions or issues like bugs or
            viruses.
          </li>
          <li>
            Access to the application may not always be available or error-free.
          </li>
        </ul>
      </div>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Temporary Access</p>
        <p class="text-truegray-500">
          We may suspend, withdraw, or modify any part of the application
          without notice.
        </p>
      </div>
      
      <h5 class="text-lg mb-6">6. Limitations of Liability</h5>
      <div class="mb-6 ml-4">
        <p class="text-truegray-500">
          We will not be liable for:
          <ul class="text-truegray-500">
            <li>Application unavailability at any time.</li>
            <li>Loss, corruption, or inaccuracy of data.</li>
            <li>Any consequential damages, including loss of profits or data, arising from your use of
              the application.</li>
          </ul>
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Third-Party Liability</p>
        <p class="text-truegray-500">
          Schooled is not liable for Hail's actions or omissions, and vice versa.
        </p>
      </div>

      <h5 class="text-lg mb-6">7. Data Protection and Security</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Data Storage and Security</p>
        <p class="text-truegray-500">
          We implement appropriate technical and organisational measures to protect user data
          against unauthorised access, alteration, or destruction. While we strive to maintain high
          standards of security, we cannot guarantee absolute protection. Users are encouraged to
          use strong passwords, avoid sharing account details, and regularly review their account
          settings for added security.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Breach Notification</p>
        <p class="text-truegray-500">
          In the event of a data breach, we will notify affected users within 48 hours and provide
          details about the nature of the breach and the measures taken to mitigate its impact.
        </p>
      </div>

      <h5 class="text-lg mb-6">8. Third-Party Integrations</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Use of Third-Party Services</p>
        <p class="text-truegray-500">
          The application integrates with third party applications to deliver the service. We are not
          responsible for the actions or omissions of third-party service providers.
        </p>
      </div>

      <h5 class="text-lg mb-6">9. User Responsibilities</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Accuracy of Information</p>
        <p class="text-truegray-500">
          You are responsible for ensuring all information entered into the application, including
          member details, information, dates (as examples), is accurate and complete.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Compliance</p>
        <p class="text-truegray-500">
          Users must ensure their use of the application complies with applicable laws, regulations,
          and school policies.
        </p>
      </div>

      <h5 class="text-lg mb-6">10. Suspension of Registration</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">User Suspension</p>
        <p class="text-truegray-500">
          You may suspend your account and remove your profile via the application or by contacting
          support@hail.to. We may retain your profile information for up to 90 days for record-
          keeping purposes.
        </p>
      </div>

      <h5 class="text-lg mb-6">11. Termination of Registration</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Termination Rights</p>
        <p class="text-truegray-500">
          We reserve the right to refuse registration or terminate your account at our discretion.
          Grounds for termination include receiving complaints about your actions, breaching these
          terms, or providing false information during registration.
        </p>
      </div>

      <h5 class="text-lg mb-6">12. Miscellaneous</h5>
      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Privacy Policy</p>
        <p class="text-truegray-500">
          We collect, use, and disclose personal information in accordance with our Privacy Policy.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Amendments to Terms</p>
        <p class="text-truegray-500">
          We may update these terms by posting revisions on our application. Continued use of the
          application after updates constitutes acceptance of the revised terms.
        </p>
      </div>

      <div class="mb-6 ml-4">
        <p class="font-bold mb-2 text-sm">Severability</p>
        <p class="text-truegray-500">
          If any part of these terms is deemed invalid or unenforceable, the remaining provisions
          remain in effect.
        </p>
      </div>

      <div class="mb-6 ml-4 pb-4 border-b border-truegray-500">
        <p class="font-bold mb-2 text-sm">Governing Law</p>
        <p class="text-truegray-500">
          These terms are governed by the laws of New Zealand. Any disputes will be resolved in New
          Zealand courts.
        </p>
      </div>

      <p class="text-truegray-500">If you have questions about these Terms of Service, please contact us at 
        <a
          href="mailto:support@hail.to"
          rel="noopener noreferrer"
          target="_blank"
          >support@hail.to</a
        >
      </p>
    </template>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import Modal from './Modal.vue'
import { LINKS } from '../../constants.js'

export default defineComponent({
  name: 'ModalTerms',

  components: {
    Modal,
  },

  setup() {
    const MODAL_CONFIG = {
      trigger: {
        text: 'Terms',
        as: 'a',
        classes: 'brand__link--white-secondary',
      },

      contentClasses: 'max-h-[85vh] overflow-y-auto p-10',
    }

    return {
      LINKS,
      MODAL_CONFIG,
    }
  },
})
</script>

<style scoped>
li {
  list-style: disc;
  margin-left: 1rem;
}
</style>